import css from 'styled-jsx/css';
import { CSSProperties, FC } from 'react';

import Clickable, { ClickableProps } from '~/app/components/Clickable';
import toSizedImageUrlNext from '~/app/lib/toSizedImageUrlNext';
import ImageIcon from '~/app/components/Icon/ImageIcon';
import Image from '~/app/components/Image';
import Text from '~/app/components/Text';
import classNames from 'classnames';
import Box from '~/app/components/Box';
import { usePageTheme } from '../../../ItemPageEdit/addons/theme/PageThemeContext';
import { toRgba } from '~/app/lib/utils/color';

export interface HorizontalItemProps extends Omit<ClickableProps, 'children'> {
  text?: JSX.Element | string;
  image: string | undefined;
  imageStyle?: CSSProperties;
  textLineClamp?: number;
}

const BORDER_RADIUS = '1.6rem';

const HorizontalItem: FC<HorizontalItemProps> = ({
  image,
  text,
  imageStyle,
  textLineClamp = 2,
  ...clickableProps
}) => {
  const pageTheme = usePageTheme();

  const { className, styles } = css.resolve`
    :hover :global(.text) {
      opacity: 1 !important;
    }
  `;

  return (
    <Clickable
      className={classNames(className)}
      positionRelative
      withHoverStyle={false}
      {...clickableProps}
      withHoverOpacityFrom={0.8}
    >
      <div
        className="image"
        style={{
          position: 'relative',
          borderRadius: BORDER_RADIUS,
          overflow: 'hidden',
          background: toRgba(pageTheme.textColor, 0.04),
          border: `1px solid ${toRgba(pageTheme.textColor, 0.2)}`,

          ...imageStyle,
        }}
      >
        {image ? (
          <Image
            src={
              image &&
              toSizedImageUrlNext({
                url: image,
                // max width of image is 33% of container which is about 160px x 2 (retina)
                width: 300,
                quality: 40,
              })
            }
            // border-radius must be applied to image
            // directly to avoid ios rendering glitch
            borderRadius={BORDER_RADIUS}
            aspect={1 / 1}
            pointerEvents="none"
            isLazy
            imgStyle={{
              filter: 'saturate(0.66)',
            }}
          />
        ) : (
          <Box centerContent padding="0 0 100%">
            <Box coverParent centerContent>
              <ImageIcon positionAbsolute size="6rem" opacity={0.5} />
            </Box>
          </Box>
        )}
      </div>
      <Text
        className="text"
        size={12}
        lineClamp={textLineClamp}
        centered
        padding="0.7rem 0.5rem 0"
        lineHeight="1.3em"
      >
        {text}
      </Text>
      {styles}
    </Clickable>
  );
};

export default HorizontalItem;
